$(document).on('turbolinks:load', function () {
  var order_timepicker_from = $('#order_datetimepicker');
  if (order_timepicker_from.get(0)) {
    order_timepicker_from.datetimepicker({
      format: 'YYYY/MM/DD HH:mm',
      stepping: 15,
      defaultDate: null,
      locale: 'ja'
    });
  }

  var order_timepicker_to = $('#order_datetimepicker_end');
  if (order_timepicker_to.get(0)) {
    order_timepicker_to.datetimepicker({
      format: 'YYYY/MM/DD HH:mm',
      stepping: 15,
      defaultDate: null,
      locale: 'ja'
    });
  }

  if (order_timepicker_from.get(0) && order_timepicker_to.get(0)) {
    order_timepicker_from.on("change.datetimepicker", function (e) {
      order_timepicker_to.datetimepicker('minDate', false);
      order_timepicker_to.datetimepicker('maxDate', false);
      order_timepicker_to.datetimepicker('minDate', moment(e.date).add(30, 'm'));
      order_timepicker_to.datetimepicker('maxDate', moment(e.date).add(180, 'm'));
      order_timepicker_to.datetimepicker('date', moment(e.date).add(30, 'm'));
    });
  }

  $('input[name="order[order_datetime_asort]"]:radio').change(function () {
    if ($(this).val() == 'time_order') {
      $('#order_order_datetime').removeAttr('disabled');
      $('#order_order_datetime_end').removeAttr('disabled');
    } else {
      $('#order_order_datetime').attr('disabled', 'disabled');
      $('#order_order_datetime_end').attr('disabled', 'disabled');
    }

  });

  $('input[name="order[receipt_flag]"]:radio').change(function () {
    if ($(this).val() == 'receipt_flag_on') {
      $('#order_receipt_address').removeAttr('disabled');
    } else {
      $('#order_receipt_address').attr('disabled', 'disabled');
    }
  });

  receipt_flag_state();

  $('input[name="order[pay_asort]"]:radio').change(function () {
    receipt_flag_state();
  });

  coupon_view_state();

  $('#btn_modal_ok').click(function () {
    check_coupon($('#input_coupon_code').val());
    return false;
  });

  $('#add_coupon_code').click(function () {
    coupon_view_state();
    $('#coupon_code_modal').modal({ 'backdrop': 'static' });
  });

  $('#clear_coupon_btn').click(function () {
    $('#order_code').val("");
    coupon_view_state();
    return false;
  });


});

var coupon_view_state = function () {
  var order_code_elm = $('#order_code');
  if (order_code_elm.get(0)) {
    var coupon_code = order_code_elm.val();
    if (coupon_code !== null && coupon_code.length > 0) {
      $('#add_coupon_code').hide();
      $('#added_coupon_area').show();
    } else {
      $('#add_coupon_code').show();
      $('#added_coupon_area').hide();
      $('#added_coupon_code').text("");
    }
  }
  $('#coupon_code_input').removeClass("field_with_errors");
  $('#coupon_code_error').text("");
  $('.dialog-has-error').hide();
};

var receipt_flag_state = function () {
  var pay_asort_elm = $('input[name="order[pay_asort]"]:checked');
  if (pay_asort_elm.get(0)) {
    var pay_asort = pay_asort_elm.val();
    if (pay_asort == 'after_invoicing') {
      $('input[name="order[receipt_flag]"]').val(["receipt_flag_off"]);
      $('input[name="order[receipt_flag]"]').attr('disabled', 'disabled');
      $('#order_receipt_address').attr('disabled', 'disabled');
      $('#buyer_info').fadeIn("slow");
    } else {
      $('input[name="order[receipt_flag]"]').removeAttr('disabled');
      $('#order_receipt_address').removeAttr('disabled');
      $('#buyer_info').fadeOut("slow");
    }
  }
};

window.coupon_view_state = coupon_view_state;

var check_coupon = function (coupon) {
  $.ajax({
    url: "/order_sessions/check_coupon",
    type: "POST",
    data: { code: coupon },
    dataType: "script"

  }).done(function (data) {

  }).fail(function (data) {

  });
};